import request from '@/axios'
import type { UserType, AdminInfo } from './types'

interface RoleParams {
  roleName: string
}

// 登陆
export const loginApi = async (data: UserType): Promise<IResponse<AdminInfo & UserType>> => {
  return request.post({
    url: '/v1/auth',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 退出
export const loginOutApi = (): Promise<boolean> => {
  return Promise.resolve(true)
}

// 获取管理员信息
export const getAdminInfo = (adminId: number): Promise<IResponse<AdminInfo>> => {
  return request.get({ url: `/api/v1/admin/${adminId}` })
}

export const getAdminRoleApi = (
  params: RoleParams
): Promise<IResponse<AppCustomRouteRecordRaw[]>> => {
  return request.get({ url: '/mock/role/list', params })
}

export const getTestRoleApi = (params: RoleParams): Promise<IResponse<string[]>> => {
  return request.get({ url: '/mock/role/list2', params })
}
